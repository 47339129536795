import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Img from 'gatsby-image'
import { IBlog } from '../models/blog'
import getFormattedDate from '../utils/getFormattedDate'
import ReactMarkdown from 'react-markdown'
import SubscribeNewsLetter from '../components/subscribe-newsletter'
import ArticleCard from '../components/article-card'
import '../styles/markdown.css'
const Article = ({ data }) => {
  const article = data.strapiBlogs as IBlog
  const relatedArticles = data.allStrapiBlogs.nodes as IBlog[]
  return (
    <Layout>
      <SEO title={article.title} type='article' />
      <div className='pb-16 pt-8 md:pt-14 md:mx-3/25'>
        <h1 className='text-xl font-bold mb-1 md:text-4xl'>{article.title}</h1>

        <div className='text-sm md:text-base text-primary mb-6'>
          {article.author && (
            <span className='mr-2 font-bold'>{article.author}</span>
          )}
          {
            <span>
              {article.createdOn
                ? getFormattedDate(article.createdOn)
                : getFormattedDate(article.createdAt)}
            </span>
          }
        </div>
        {article.ogImage && (
          <Img
            fluid={{
              ...article.ogImage.childImageSharp.fluid,
              // aspectRatio: 2.5,
            }}
            imgStyle={{ objectFit: 'contain' }}
            alt='feature'
            className='w-full mb-6'
          />
        )}

        <ReactMarkdown
          escapeHtml={false}
          source={article.content.replace(/__/g, '_')}
          className='text-sm md:text-lg leading-7 md:leading-8 markdown'
        />
      </div>
      <div className='mb-8'>
        <SubscribeNewsLetter />
      </div>
      {relatedArticles.length > 0 ? (
        <>
          <h2 className='text-2xl font-bold'>
            More Articles <br className='md:hidden' /> from our Blog
          </h2>

          <div className='grid grid-cols-1 md:grid-cols-2 col-gap-12'>
            {relatedArticles.map((article) => (
              <>
                <ArticleCard
                  article={article}
                  borderBelowTextSectionOnly={true}
                  dateFirst={true}
                />
              </>
            ))}
          </div>
        </>
      ) : null}
    </Layout>
  )
}

export default Article
export const query = graphql`
  query getArticle($id: String!, $type: String!) {
    strapiBlogs(id: { eq: $id }) {
      ogImage {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      slug
      title
      type
      published
      content
      createdAt
      createdOn
      author
    }
    allStrapiBlogs(limit: 2, filter: { id: { ne: $id }, type: { eq: $type } }) {
      nodes {
        ogImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        slug
        title
        type
        published
        content
        createdAt
        createdOn
        author
      }
    }
  }
`
