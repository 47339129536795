import React from "react";
import {IBlog} from "../models/blog";
import {Link} from "gatsby";
import Img from "gatsby-image";
import getFormattedDate from "../utils/getFormattedDate";

const NavLink: React.FC<{article: IBlog}> = ({article, children}) => {
  return (
    <>
      {article.externalLink ? (
        <a href={article.externalLink} target="_blank">
          {children}
        </a>
      ) : (
        <Link to={`/${article.type.toLowerCase()}/${article.slug}`}>
          {children}
        </Link>
      )}
    </>
  );
};
const ArticleCard: React.FC<{
  article: IBlog;
  borderBelowTextSectionOnly?: boolean;
  dateFirst?: boolean;
}> = ({article, borderBelowTextSectionOnly = false, dateFirst = false}) => {
  return (
    <div
      className={`grid grid-row-2 ${
        article.ogImage ? "md:grid-cols-blogListLarge" : "md:grid-cols-1"
      } col-gap-10 pb-4 pt-8 ${
        borderBelowTextSectionOnly
          ? ""
          : "border-b-2 border-solid border-primary"
      }`}
    >
      {article.ogImage && (
        <NavLink article={article}>
          <Img
            fluid={article.ogImage.childImageSharp.fluid}
            alt="feature"
            className="h-37 mb-4 md:min-h-full  md:w-64 object-contain "
          />
        </NavLink>
      )}
      <div
        className={` flex ${
          borderBelowTextSectionOnly
            ? "border-b-2 border-solid border-primary pb-4 md:pb-0"
            : ""
        } ${dateFirst ? "flex-col-reverse justify-end" : "flex-col"}`}
      >
        <NavLink article={article}>
          <h2 className="font-bold mb-2 md:text-2xl">{article.title}</h2>
        </NavLink>
        <span className="text-sm">
          {article.createdOn
            ? getFormattedDate(article.createdOn)
            : getFormattedDate(article.createdAt)}
        </span>
      </div>
    </div>
  );
};

export default ArticleCard;
