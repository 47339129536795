import React, {useState} from "react";
import Button from "./button";
import NewsletterForm from "./newsletter-form";
const close = require("../images/close.svg");

function SubscribeNewsLetter({heading = "Subscribe to newsletter"}) {
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <div className="md:h-27  w-full bg-primary flex flex-col md:flex-row px-6 md:px-10 py-6 md:py-0 md:items-center">
      <h2 className=" text-white font-bold text-2xl md:text-4xl md:whitespace-no-wrap md:pr-10 mb-2 md:mb-0">
        {heading}
      </h2>

      <span
        className=" text-white whitespace-pre-wrap text-sm 
        md:pr-10 mb-5 md:mb-0"
      >
        Get a bi-monthly email with news, interesting reads, and useful tips
        about startups.
      </span>

      <Button
        secondary
        className="mx-auto md:float-right w-full md:w-29"
        fontSize="sm"
        onClick={() => setModalOpen(true)}
      >
        Subscribe{" "}
      </Button>

      {isModalOpen && (
        <div
          className="fixed inset-0 z-10"
          style={{backgroundColor: "rgba(255,255,255,0.90)"}}
        >
          <NewsletterForm
            isHorizontal={false}
            closeButton={() => (
              <img
                src={close}
                alt="close"
                className="absolute top-8 right-8 cursor-pointer "
                onClick={() => setModalOpen(false)}
              />
            )}
          />
        </div>
      )}
    </div>
  );
}

export default SubscribeNewsLetter;
